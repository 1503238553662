import React, {useEffect} from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import Layout from "../components/layout"
import Bio from "../components/bio"

import DataTable from "react-data-table-component"
import Dropdown from "react-dropdown"
import 'react-dropdown/style.css'
import _ from "lodash"


// Define filtering components outside so that they don't re-render
// and input doesn't lose its focus
const Input = styled.input``;
const Button = styled.a``;

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <div className="w-100">
    <Input id="search" className="pv1 mb2 mr2" type="text" placeholder="Search..." value={filterText} onChange={onFilter} />
    <Button className="f6 link dim ph3 pv2 mb2 dib white pointer" style={{backgroundColor: '#2D3356'}} onClick={onClear}>Clear</Button>
  </div>
);



export default props => {

  // Two react states two dropdowns (expense type vs vendor, and year)
  const [year, setYear] = React.useState('2023')
  const [breakdown, setBreakdown] = React.useState('recipient/vendor')

  // Filtering
  const [filterText, setFilterText] = React.useState('')

  // Make sure document exists before enabling pagination, otherwise Gatsby won't compile
  // https://github.com/jbetancur/react-data-table-component/blob/master/src/DataTable/util.js#L120
  const [pagination, setPagination] = React.useState(false)
  useEffect(() => {
    if (document) {
      setPagination(true);
      setFilterText(props.location.hash.replace(/%20/g, ' ').substr(1) || '')
    }
  }, [])

  const data = props.pageContext;

  // Group expenses
  const g = breakdown === 'recipient/vendor' ? 'Vendor' : 'Description'
  const grouped = _.groupBy(data.expenses.filter(o => o.FiscalYear === year)
    .map(function(o) {o.Amount = parseFloat(o.Amount); return o}), g)

  // Calculate totals
  var groupTotal = Object.keys(grouped)
    .filter(x => x.toUpperCase().includes(filterText.toUpperCase()))
    .map(function(x) {
      return {
        group: x,
        total: grouped[x].reduce((a, b) => a + b.Amount, 0)
      }
  })

  const Subtable = ({ data }) => (
    <DataTable
      title='Expenses by Date'

      defaultSortField='Amount'
      defaultSortAsc={false}

      columns={[
        {
          name: 'Date',
          selector: 'Date',
          //format: d => new Date(d.Date).toLocaleDateString('en-US'),
          sortable: true,
        },
        {
          name: 'Amount',
          selector: 'Amount',
          sortable: true,
          format: d => '$' + Math.round(d.Amount).toLocaleString()
        },
        {
          name: breakdown === 'recipient/vendor' ? 'Payment Category' : 'Recipient/Vendor',
          selector: breakdown === 'recipient/vendor' ? 'Description' : 'Vendor',
          sortable: true,
        }
      ]}

      data={ grouped[data.group].filter(o => o.FiscalYear === year) }

      dense

      customStyles={{
        table: {
          style: {
            width: '80%',
            marginLeft: 'auto',
            marginRight: 'auto'
          }
        },
        header: {
          style: {
            fontSize: '16px',
            width: '80%',
            marginLeft: 'auto',
            marginRight: 'auto'
          }
        }
      }}
    />
  )

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setFilterText('');
      }
    };

    return <FilterComponent
      onFilter={e => setFilterText(e.target.value)}
      onClear={handleClear}
      filterText={filterText}
    />
    }, [filterText]);

  return (
    <Layout>

      <div className="cf pv3">
        <h2 className="f2 fw3 ma0 di">
          <Link to="/checkbook" className="link black underline-hover">Checkbook</Link>
        </h2>
        <h2 className="f2 fw3 ma0 moon-gray di"> &gt; </h2>
        <h1 className="f2 fw4 ma0 mr3 di">{data.organization}</h1>
      </div>

      <Bio bio={data.bio.Bio} home={data.bio.Home} report={data.bio.Report} />

      <div className="cf bg-light-gray pv2 ph3 sticky bb bw1 b--moon-gray">

        <h4 className="f4 fl mv2 fw4"> Expenses broken down by </h4>
          
        <Dropdown
          options={['recipient/vendor', 'payment category']}
          onChange={function(v) {  setBreakdown(v.value); setFilterText(''); }}
          className='mw5 fl mh2'
          placeholder={breakdown}
          value={breakdown}
        />

        <h4 className="f4 fl mv2 fw4"> for </h4>

        <Dropdown
          options={['2019', '2020', '2021', '2022', '2023']}
          onChange={(v) => setYear(v.value) }
          className='mw4 fl mh2'
          placeholder={year}
          value={year}
        />

        <h4 className="f4 fl mv2 fw4">
          {data.organization === 'Connecticut Housing Finance Authority' ? 'calendar': 'fiscal'} year.
        </h4>

      </div>

      <p className="f4 pt2 pb3">
      The table below shows checkbook-level expenses of {data.organization} in fiscal year {year}. This data includes total received payments for 
      expenses. Some agencies, but not all, may also include investments, 
      bond payments, and line of credit repayment activity. Click on a table 
      row to see individual payments with dates for that recipient/vendor. 
      You can sort all columns by clicking on the header.
        
        {/* The table below shows total received payments
        for {breakdown === 'recipient/vendor' ? 'expenses, investments, bond payments, and line of credit repayment activity' : 'each payment category'} of {data.organization} in {year} fiscal year.
        Click on a table row to see individual payments with dates for that {breakdown}.
        You can sort all columns by clicking on the header. */}
      </p>
      
      <DataTable

        title={data.organization}
        keyField={breakdown === 'recipient/vendor' ? 'Recipient/Vendor' : 'Payment Category'}
        noHeader

        columns={[
          {
            name: breakdown === 'recipient/vendor' ? 'Recipient/Vendor' : 'Payment Category',
            selector: 'group',
            sortable: true,
          },
          {
            name: 'Total Expenses',
            right: true,
            selector: 'total',
            sortable: true,
            format: d => '$' + Math.round(d.total).toLocaleString()
          }
        ]}

        defaultSortField='total'
        defaultSortAsc={false}

        data={groupTotal}

        expandableRows
        expandOnRowClicked
        expandableRowsComponent={<Subtable />}

        subHeader
        subHeaderComponent={subHeaderComponentMemo}

        pagination={pagination}
        paginationPerPage={20}
        paginationRowsPerPageOptions={[20, 50, 100]}

        highlightOnHover
        persistTableHead

        customStyles={{
          subHeader: {
            style: {
              paddingLeft: '0'
            }
          },
          header: {
            style: {
              fontSize: '28px'
            }
          }
        }}
      />

    </Layout>
  )
}